.menu {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    z-index: 4;
    font-family: Lato,Arial,Helvetica,sans-serif;
    transition: all .2s ease-in-out;
    text-align: right;
    display: none;
}
.menu_opened {
    visibility: visible;
    opacity: 1;
}

.menu__container {
    width: 310px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    background: #000;
    color: #fff;
    overflow: scroll;
}

.menu__logo {
    margin: 10px 0 0 10px;
    width: 200px;
    display: block;
}

.menu__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 15px 0 0;
}

.menu__button-block {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.menu__button-image {
    color: #ffb203;
}

.menu__link {
    color: #fff;
    display: block;
    margin: 15px 0 0 35px;
    text-decoration: none;
    text-align: left;
}
.menu__link_active {
    text-decoration: underline;
}

.menu__info-title {
    font-size: 13pt;
    line-height: 13pt;
    margin:  30px 10px 15px 0;
}

.menu__info-phone {
    font-weight: 600;
    color: #ffb203;
    line-height: 13pt;
    font-size: 17pt;
    margin:  0 10px 0 0;
    text-decoration: none;
}

.menu__info-interval {
    font-size: 13pt;
    line-height: 25px;
    margin:  15px 10px 0 0;
}
.menu__info-interval-span {
    color: #ffb203;
}

.menu__time-box {
    display: flex;
    margin: 7px 0 0;
    font-weight: 400;
    font-size: 12pt;
}

.menu__time-box-left {
    width: 50%;
}

.menu__time-box-right {
    line-height: 35pt;  
    display: flex;
    align-items: baseline;
    width: 50%;
    justify-content: space-evenly;
}

.menu__time-box-image {
    color: #ffb203;
}

.menu__time-box-minutes {
    font-size: 30pt;
    margin: 0;
}

.menu__time-box-unit {
    line-height: 40pt;
    font-size: 16pt;
    margin: 0;
}

.menu__close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    transform: scale(1.3);
}
.menu__close:hover {
    transform: scale(1.85);
}



@media (max-width: 911px) {
    .menu {
        display: block;
    }
 }