.header {
    background: #070707;
    font-family: Lato,Arial,Helvetica,sans-serif;
}

.header__presentation {
    max-width: 1280px;
    margin: auto;
    color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.header__logo-container {
}

.header__logo {
    margin: 22px 0 14px 14px;
    width: 200px;
}

.header__orders {
}

.header__orders-title {
    font-weight: 500;
    font-size: 15pt;
    margin-top: 22px;
    line-height: 19px;
    margin-bottom: 10px;
    text-align: center  ;
}

.header__counter {
    width: 126px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.header__counter-container {
    color: #fff;
}

.header__counter-container:last-of-type  {
    color: #ffb203;
}

.header__counter-digit {
    margin-left: 2.5px;
    margin-right: 2.5px;
    font-size: 50pt;
    border-radius: 5px;
    line-height: 58px;
}

.header__info {
    text-align: right;
}

.header__info-title {
    font-weight: 400;
    margin-top: 18px;
    font-size: 17pt;
    line-height: 17pt;
}

.header__info-phone {
    font-weight: 600;
    margin-top: 10px;
    line-height: 17pt;
    font-size: 21pt;
    color: #ffb203;
    text-decoration: none;
}

.header__info-interval {
    font-size: 16pt;
    line-height: 30px;
    font-weight: 400;
    margin: 2px 0 0;
}

.header__info-interval-span {
    color: #ffb203;
}

.header__time-box {
    display: flex;
    margin: 7px 0 0;
    font-weight: 400;
    font-size: 15pt;
}
    
.header__time-box-left {
    width: 50%;
}

.header__time-box-right {
    line-height: 35pt;
    display: flex;
    align-items: baseline;
    width: 50%;
    justify-content: space-evenly;
}

.header__info-menu {
    display: none;
    cursor: pointer;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    color: #fff;
    margin: 20px;
}

.header__time-box-image {
    margin: 0;
    color: #ffb203;
}

.header__time-box-minutes {
    font-size: 38pt;
    margin: 0;
}

.header__time-box-unit {
    line-height: 44pt;
    float: right;
    font-size: 20pt;
    margin: 0;
}

.header__nav {
    height: 60px;
    background-color: #e3e3e3;
}

.header__nav-bar {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    max-width: 1280px;
    margin: auto;
    font-family: Lato,Arial,Helvetica,sans-serif;
}

.header__nav-place {
    margin: 18px 0 0;
    display: flex;
    column-gap: 5px;
    padding: 0 0 0 10px;
    align-items: center;
}

.header__nav-place-block {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

.header__nav-place-image {
    color: rgba(164,0,0,.643137);
    cursor: pointer;
}

.header__nav-place-text {
    cursor: pointer;
    margin: 0;
}

.header__nav-menu {
    display: flex;
    margin: 18px 0 0;
    align-items: center;
}

.header__nav-menu-link {
    color: #000;
    text-decoration: none;
    padding: 0 20px;
}
.header__nav-menu-link_active {
    font-weight: 500;
    font-size: 15pt;
}

.header__nav-lk-enter {
    display: flex;
    column-gap: 5px;
    margin: 18px 0 0;
    align-items: center;
}

.header__nav-lk-enter-text {
    cursor: pointer;
    margin: 0;
}



@media (max-width: 1279px) { 
    .header__info {
        padding: 0 10px 0 0;
    }
    
}



@media (max-width: 1083px) {
    .header__logo {
        width: 200px;
    }

    .header__counter-digit {
        font-size: 40pt;
    }

    .header__info-title {
        font-size: 13pt;
        line-height: 13pt;
    }

    .header__info-phone {
        line-height: 13pt;
        font-size: 17pt; 
    }

    .header__info-interval {
        font-size: 13pt;
        line-height: 25px;
    }

    .header__time-box {
        font-size: 12pt;
    }

    .header__time-box-minutes {
        font-size: 30pt;
    }

    .header__time-box-unit {
        line-height: 40pt;
        font-size: 16pt;
    }

    .header__nav-menu-link {
        padding: 0 10px;
    }
}



@media (max-width: 911px) { 

    .header__presentation {
        grid-template-columns: 40% 40% 20%;
    }

    .header__orders {
        display: flex;
        align-items: center;
    }

    .header__orders-title {
        margin: 0;
    }

    .header__info {
        display: flex;
        align-items: start;
        justify-content: end;
    }

    .header__info-menu {
        display: block;
    }

    .header__info-title {
        display: none;
    }

    .header__info-phone {
        display: none;
    }

    .header__info-interval {
        display: none;
    }

    .header__time-box {
        display: none;
    }

    .header__nav {
        display: none;
    }

    .header__nav-place-image {
        color: #ffb203;
    }
}



@media (max-width: 590px) {
    .header__presentation {
        grid-template-columns: 50% 50%;
    }

    .header__logo {
        margin-left: calc(100% - 75px);
        width: 150px;
    }

    .header__orders {
        display: none;
    }
    
}