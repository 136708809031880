.food {
    background-color: #e3e3e3;
    font-family: Lato,Arial,Helvetica,sans-serif;
}

.sections-menu {
    height: 40px;
    background: #070707;
    font-family: Lato,Arial,Helvetica,sans-serif;
    position: sticky;
    top: 0;
    z-index: 2;
}
  
.sections-menu__container {
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    color: #fff;
    justify-content: space-evenly;
    height: 100%;
    align-items: center;
}  
  
.section {
    margin: 0;
    cursor: pointer;
    text-wrap: nowrap;
}
  
.section_active {
    font-size: 15pt;
    font-weight: 500;
    white-space: nowrap;
}
  
.section-select {
    margin: 0 auto;
    display: none;
    font-weight: 500;
    font-size: 15pt;
    width: 180px;
    height: 30px;
}

.food__container {
    max-width: 1280px;
    margin: 20px auto 0;
    display: flex;
    position: relative;
}

.food__menu {
    display: grid;
    width: 75%;
    grid-template-columns: repeat(4, 1fr);
}

.food__item {
    position: relative;
    height: 350px;
    display: flex;
    justify-content: center;
}

.food__item-image {
    position: absolute;
    cursor: pointer;
    transition: all .2s ease-in-out;
    top: 0;
    width: 200px;
    height: 200px;
}
.food__item-image:hover {
    top: 18px;
}

.food__item-name {
    margin: 238px 0 0 10px;
    z-index: 1;
    position: relative;
    max-width: 200px;
    font-size: 14pt;
    font-weight: 700;
    position: relative;
    line-height: 1.2em;
    max-height: 3.6em;
    overflow: hidden;
    
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.food__item-container {
    position: absolute;
    bottom: 12px;
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.food__item-button {
    text-align: center;
    height: 30px;
    border: none;
    color: #fff;
    border-radius: 20px;
    float: left;
    width: 95px;
    margin-left: 10px;
    background-color: #000;
    transition: all .2s ease-in-out;
    font: inherit;
    cursor: pointer;
}
.food__item-button:hover{
    background-color: #651111;
}

.food__item-price-container {
    
}

.food__item-price {
    font-weight: 400;
    font-size: 14pt;
    padding-right: 10px;
    margin: 0;
    text-decoration: line-through;
}
.food__item-price_active {
    text-decoration: none;
}

.food__item-price-discount{
    font-weight: 400;
    font-size: 14pt;
    padding-right: 10px;
    margin: 0;
    display: none;
    color: rgb(164, 0, 0);
}
.food__item-price-discount_active {
    display: inline;
}

.food__item-x{
    position: absolute;
    background: burlywood;
    top: 185px;
    right: 10px;
    transition: all .2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 74px;
    height: 35px;
    font-size: 15pt;
    justify-content: space-evenly;
}
.food__item-x_active {
    opacity: 1;
    visibility: visible;
}

.food__item-x-btn {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font-size: 15pt;
    cursor: pointer;
}
.food__item-x-btn_remove:hover {
    color: rgba(255,0,0,.427451);
}
.food__item-x-btn_add:hover {
    color: #39ad1c;
}

.food__item-discount {
    display: none;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 0;
    position: absolute;
    background: linear-gradient(270deg,#a40000,#b93c3c);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 14pt;
    font-weight: 600;
    color: #fff;
    z-index: 1;
}
.food__item-discount_active{
    display: flex;
}

.food__basket-overlay {
    display: none;
}
.food__basket-overlay_opened {
    display: none;
}

.food__basket{
}

.food__basket-container {
    position: sticky;
    top: 40px;
    width: 280px;
    min-height: 294px;
    max-height: 85vh;
    color: #000;
    background: burlywood;
    border-radius: 10px;
    line-height: 2em;
    transition: all .2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    overflow: auto;
}

.food__basket-title {
    margin: 30px auto 20px;
    font-size: 16pt;
}

.food__basket-image {
    margin: 0 auto 30px;
}

.food__basket-text {
    margin: 0 auto;
    font-size: 14pt;
}
/* .food__basket-text:first-of-type{
    color: #a40000;
} */

.food__basket-subtitle {
    margin: 30px auto -30px;

}

.food__basket-list {
    width: 80%;
    overflow: auto;
    max-height: calc(85vh - 318px);
    min-height: 40px;
    scrollbar-color: #000 burlywood;
    scrollbar-width: thin;
}
.food__basket-list::-webkit-scrollbar{
    width: 10px;
    background-color: burlywood;
}
.food__basket-list::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 9em;
}

.food__basket-item {
    display: flex;
    margin: 5px 0;
}

.food__basket-item-name {
    font-size: 12pt;
    width: 145px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    overflow: hidden;
}

.food__basket-item-buttons {
    display: flex;
    background-color: #000;
    border-radius: 20px;
    border: none;
    width: 74px;
    height: 35px;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.food__basket-item-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 15pt;
    transition: all .2s ease-in-out;
    margin: 0;
    padding: 0 6px;
    cursor: pointer;
}
.food__basket-item-button_remove:hover {
    color: rgba(255,0,0,.427451);
}
.food__basket-item-button_add:hover {
    color: #39ad1c;
}

.food__basket-item-x {
    margin: 0;
    padding: 0 7px;
}

.food__basket-sum-text {
    margin: 16px 0 0;
    font-size: 21px;
}

.food__basket-sum {
    margin: 10px 0 0;
    font-size: 21px;
    /* color: rgb(164, 0, 0); */
}

.food__basket-order-button {
    margin: 20px 0;
    height: 30px;
    border: none;
    color: #fff;
    border-radius: 20px;
    width: 155px;
    background-color: #000;
    transition: all .2s ease-in-out;
    font-size: 13pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.food__basket-order-button:hover{
    background-color: #651111;
}

.food__basket-open-button {
    border: none;
    cursor: pointer;
    background: burlywood;
    border-radius: 20px;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
    position: sticky;
    top: 40px;
    transition: all .2s ease-in-out;
    z-index: 1;
    display:  none;
    color: #000;
}

.food__basket-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    color: #000;
    transition: all .2s ease-in-out;
    transform: scale(1.3);
    display: none;
}
.food__basket-close:hover {
    transform: scale(1.85);
}

.food__basket-order-info {
    text-align: center;
    font-size: 14pt;
}

/* .food__basket-order-info-span {
    color: #a40000;
} */

.food__void-text {
    grid-column-start: span 4;
    text-align: center;
}

 @media (max-width: 1166px) {
    .food__menu {
        grid-template-columns: repeat(3, 1fr);
    }
}



@media (max-width: 960px) {
    .sections-menu__container {
        justify-content: space-evenly;
        column-gap: 10px;
    }
     
    .food__menu {
        grid-template-columns: repeat(2, 1fr);
    }
}



@media (max-width: 745px) {
    .food {
        padding:  0 0 20px;
    }

    .food__menu {
        width: 100%;
    }

    .food__item {
        height: 400px;
        display: flex;
        justify-content: center;
    }

    .food__item-name {
        max-width: 90%;
    }

    .food__item-container {
        flex-direction: column-reverse;
        row-gap: 10px;
        width: 90%;
    }

    .food__item-button {
        width: 100%;
        height: 40px;
        font-size: 23px;
        margin: 0;
        padding: 0;
    }

    .food__basket {
        position: absolute;
        background: none;
        height: 100%;
        pointer-events: none;
        width: 90%;
        left: 5%;
    }

    .food__basket-overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.7);
        z-index: 2;
        font-family: Lato,Arial,Helvetica,sans-serif;
        transition: all .2s ease-in-out;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }
    .food__basket-overlay_opened {
        visibility: visible;
        opacity: 1;
        overflow: scroll;
        pointer-events: all;
    }

    .food__basket-container {
        visibility: hidden;
        opacity: 0;
        width: 100%;
    }
    .food__basket-container_opened {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
    }

    .food__basket-list {
        padding: 0 0 0 35px;
    }

    .food__basket-item-name {
        width: 80%;
    }

    .food__basket-order-button {
        height: 41px;
        width: 90%;
    }

    .food__basket-open-button {
        display: block;
        pointer-events: all;
    }
    .food__basket-open-button_active_x {
        display: flex;
        width: 100px;
        justify-content: space-evenly;
        align-items: center;
    }
    .food__basket-open-button_active_order {
        display: flex;
        width: 90%;
        justify-content: space-evenly;
        align-items: center;
    }
    .food__basket-open-button_closed {
        display: none;
    }
    
    .food__basket-x {
        margin: 0;
        font-size: 19px;
        visibility: hidden;
        opacity: 0;
        display: none;
    }
    .food__basket-x_active {
        visibility: visible;
        opacity: 1;
        display: inline;
    }

    .food__basket-order {
        margin: 0;
        font-size: 17px;
        visibility: hidden;
        opacity: 0;
        display: none;
        width: 70%;
    }
    .food__basket-order_active {
        visibility: visible;
        opacity: 1;
        display: inline;
    }

    .food__basket-close {
        display: block;
    }
}



@media (max-width: 580px) {
    .sections-menu__container {
      justify-content: flex-start;
      overflow: auto;
      padding: 0 20px;
      justify-content: space-between;
    }
  }



@media (max-width: 460px) { 
    .food__menu {
        grid-template-columns: repeat(1, 1fr);
    }
    
    .food__item {
        display: flex;
        justify-content: center;
    }
}
