.popup__complex {
    /* display: flex; */
    margin: 10% auto;
    background: #e3e3e3;
    width: 820px;
    min-height: 275px;
    padding: 25px;
    border-radius: 20px;
    position: relative;
    /* flex-direction: column; */
}

.popup__complex-title {
    margin: 0 0 35px;
    text-transform: uppercase;
    font-size: 18.72px;
    font-weight: 500;
    border-bottom: 1px solid #a40000;
    padding: 0 0 10px;
}

.popup__complex-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup__complex-image {
    width: 275px;
}

.popup__complex-name {
    font-size: 18px;
    max-width: 80%;
}

.popup__complex-button {
    text-align: center;
    height: 30px;
    border: none;
    color: #fff;
    border-radius: 20px;
    width: 95px;
    background-color: rgba(164,0,0,.643137);
    transition: all .2s ease-in-out;
    font: inherit;
    cursor: pointer;
}
.popup__complex-button:hover {
    background-color: #a40000;
}
.popup__complex-button:disabled {
    background-color: #aaa;
}

.popup__complex-buttons {
    margin: 20px 0 0;
    display: flex;
    justify-content: space-between;
}

.popup__complex-picked {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: #fff;
    border-radius: 20px;
    width: 95px;
    background-color: #373;
    font: inherit;
}

.popup__complex-combo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup__complex-price {
    font-size: 21px;
    margin: 0;
}

.popup__complex-price-span {
    color: rgb(164, 0, 0);
}